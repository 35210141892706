import React, { useEffect } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";
import "react-rater/lib/react-rater.css";
import Rater from "react-rater";
import { useDispatch, useSelector } from "react-redux";
import * as moment from "moment";
import { fetchRestaurantAction, fetchRestaurantCommentsAction } from "../state/restaurant.reducer";
import { ImagesSlider } from "../components/common/imagesSlider";
import AppButton from "../components/common/button";
import SEO from "../components/seo";
import i18n from "../utils/i18n";
import { Loading } from "../components/common/loading";
import AppPageHeader from "../components/common/texts/appPageHeader";
import AppLeadingText from "../components/common/texts/appLeadingText";
import { clearLayoutAction, setLayoutAction } from "../state/layout.reducer";
import { LINKS } from "../constants/links";
import { AppContainer } from "../components/common/appContainer";

const StyledCommentWrapper = styled.div`
	border-bottom: 1px solid ${({ theme }) => theme.colors.borderGray};
	padding: 14px 0;

	p {
		margin-bottom: 0;

		&.no-opinion {
			opacity: 0.5;
		}
	}
`;
const StyledRatingWrapper = styled.div`
	align-items: center;
	display: flex;
	font-size: 18px;
	justify-content: space-between;
	text-decoration: none;
	width: 100%;
`;
const StyledRatingDescription = styled.span`
	font-size: 11px;
`;
const StyledRestaurantDescription = styled.div`
	padding: 0 0 55px;
`;
const StyledButtonWrapper = styled.div`
	margin-top: 50px;
	text-align: center;
`;

const RestaurantCommentsPage = ({ pageContext }) => {
	const { t } = useTranslation();
	const language = i18n.language.slice(0, 2).toLowerCase();
	const restaurant = useSelector((state) => state.restaurant);
	const dispatch = useDispatch();

	const { entry } = pageContext;

	const data = {
		...entry,
		...restaurant,
	};
	const { comments, loading } = restaurant;

	useEffect(() => {
		dispatch(fetchRestaurantAction(entry.alternative_id));
		dispatch(fetchRestaurantCommentsAction(entry.alternative_id));
		dispatch(
			setLayoutAction({
				backTo: `${LINKS.RESTAURANT}/${entry.slug}`,
				customLogoSrc: data?.images?.find((image) => image.type === "LOGO")?.url || null,
				customLogoUrl: data?.slug ? `${LINKS.RESTAURANT}/${data.slug}` : null,
			})
		);

		return () => {
			dispatch(clearLayoutAction());
		};
	}, [dispatch]);

	const sliderImages = data?.images?.filter((image) => image.type === "PROFILE");

	return (
		<>
			<SEO title={`${data.name} - ${t("comments.title")}`} />
			<AppContainer>
				<ImagesSlider images={sliderImages} />
			</AppContainer>
			<StyledRestaurantDescription>
				<AppContainer>
					{loading && <Loading />}
					{comments &&
						comments.length > 0 &&
						comments.map((comment) => (
							<StyledCommentWrapper key={comment.id}>
								<StyledRatingWrapper>
									<Rater interactive={false} rating={comment.rating} total={5} />
									<StyledRatingDescription>
										{moment(comment.createdAt).locale(language).format("DD/MM/YYYY, [godz] HH:mm")}
									</StyledRatingDescription>
								</StyledRatingWrapper>
								<p className={comment.comment ? "" : "no-opinion"}>
									{comment.comment ? comment.comment : t("common.noOpinion")}
								</p>
							</StyledCommentWrapper>
						))}
					{comments && comments.length === 0 && (
						<div>
							<AppPageHeader>{t("comments.header")}</AppPageHeader>
							<AppLeadingText>{t("comments.description")}</AppLeadingText>
						</div>
					)}
				</AppContainer>
				<StyledButtonWrapper>
					<AppButton as={Link} to={`/restaurant/${data.slug}`}>
						<span>{t("common.goBack")}</span>
					</AppButton>
				</StyledButtonWrapper>
			</StyledRestaurantDescription>
		</>
	);
};

export default RestaurantCommentsPage;
